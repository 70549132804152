import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'darBase64Sanitizer'
})
export class Base64SanitizerPipe implements PipeTransform {

  constructor(
    private readonly sanitizer: DomSanitizer
  ) {
  }

  public transform(value: string): unknown {
    return this.sanitizer.bypassSecurityTrustUrl(value);
  }

}


