import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { RentaNgxLibModule } from '@lib/rs-ngx';
import { StepperComponent } from './components/stepper/stepper.component';
import { BlurOnEnterDirective } from './directives/blur-on-enter.directive';
import { MatButtonModule } from '@angular/material/button';
import { Base64SanitizerPipe } from './pipes/base64-sanitizer.pipe';

/** @deprecated Usage will change, please read description below!!!
 *
 * @description What!!! How should we use it now ???
 *
 * - Will be used only to import/export real shared modules like CommonModule, TranslateModule.forChild (Those will be shared only in rentaNgxModule)
 * - For other modules like Material one's, please ONLY import the ones you need where you need them!
 * - For shared components, please create standalone ones and import it only where needed
 * - Same for shared directives, and pipes, please create standalone ones and import it only where needed
 **/
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    RentaNgxLibModule,
    MatButtonModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    RentaNgxLibModule,
    StepperComponent,
    BlurOnEnterDirective,
    MatButtonModule,
    Base64SanitizerPipe
  ],
  declarations: [StepperComponent, BlurOnEnterDirective, Base64SanitizerPipe],
})
export class SharedModule {
}
