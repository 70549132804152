import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private readonly cookieService: CookieService,
    private readonly jwtHelper: JwtHelperService
  ) {
  }

  public logout(): void {
    const domain = environment.environment === 'mock' ? 'localhost' : '.rentasolutions.org';
    this.cookieService.delete('dar-authentication', '/', domain);
  }

  /** Check token existence and expiration.
   *
   * @param none
   * @return boolean
   */
  public isUserAuthenticated(): boolean {
    const token = this.getToken();

    const tokenExpired = this.jwtHelper.isTokenExpired(token);
    return token?.length > 0 && !tokenExpired;
  }

  /** Get dar token.
   *
   * @param none
   * @return token: string
   */
  private getToken(): string {
    return this.cookieService.get('dar-authentication');
  }
}
