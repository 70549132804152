import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StepsCompletedState } from '@app/core/state/steps-completed/steps-completed.reducer';

export const selectStepsCompletedState = createFeatureSelector<StepsCompletedState>(
  'stepsCompleted'
);

export const selectStepUserInfoValid = createSelector(
  selectStepsCompletedState,
  (state) => state.stepUserInfoValid
);

export const selectStepLicensePlateValid = createSelector(
  selectStepsCompletedState,
  (state) => state.stepLicensePlateValid
);

export const selectStepReturnedVehicleValid = createSelector(
  selectStepsCompletedState,
  (state) => state.stepReturnedVehicleValid
);
