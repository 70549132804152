import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@environments/environment';

export function errorHandlerFactory(): ErrorHandler {
  if (environment.enableSentryMonitoring) {
    return Sentry.createErrorHandler({
      showDialog: false,
      logErrors: false,
    });
  }
  return new ErrorHandler();
}
