import { createReducer, on } from '@ngrx/store';
import { resetStepsCompleted, setStepLicensePlateValid, setStepReturnedVehicleValid, setStepUserInfoValid } from '@app/core/state/steps-completed/steps-completed.actions';
import { produce } from 'immer';

export interface StepsCompletedState {
  stepUserInfoValid: boolean;
  stepLicensePlateValid: boolean;
  stepReturnedVehicleValid: boolean;
}

export const initialState: StepsCompletedState = {
  stepUserInfoValid: false,
  stepLicensePlateValid: false,
  stepReturnedVehicleValid: false
};

export const stepsCompletedReducer = createReducer(
  initialState,
  on(setStepUserInfoValid, (state, { stepUserInfoValid }) => {
    return produce(state, draft => {
      draft.stepUserInfoValid = stepUserInfoValid;
    });
  }),
  on(setStepLicensePlateValid, (state, { stepLicensePlateValid }) => {
    return produce(state, draft => {
      draft.stepLicensePlateValid = stepLicensePlateValid;
    });
  }),
  on(setStepReturnedVehicleValid, (state, { stepReturnedVehicleValid }) => {
    return produce(state, draft => {
      draft.stepReturnedVehicleValid = stepReturnedVehicleValid;
    });
  }),
  on(resetStepsCompleted, () => ({
    stepUserInfoValid: false,
    stepLicensePlateValid: false,
    stepReturnedVehicleValid: false
  }))
);
