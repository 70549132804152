import { createAction, props } from '@ngrx/store';
import { LeasingCompany } from '@src/app/shared/models/leasing-company';
import { OrderInfo } from '@src/app/shared/models/order-info';
import { PickupDriver, PreviousVehicle } from '@app/shared/models/order-delivery-confirmation';
import { Base64String } from '@lib/rs-ngx';

export const setOrderInfoAndLeasingCompanies = createAction(
  '[Order] Set order info and leasing companies',
  props<{ orderInfo: OrderInfo, leasingCompanies: LeasingCompany[], img: Base64String }>(),
);

export const setPickupDriverInfo = createAction(
  '[Order] Set pickup driver info',
  props<{ driverInfo: PickupDriver }>()
);

export const setPreviousVehicle = createAction(
  '[Order] Set leasing company rs number',
  props<{ previousVehicle: PreviousVehicle }>()
);

export const setLicensePlateInput = createAction(
  '[Order] Set license plate input',
  props<{ licensePLateInput: string }>()
);

export const resetOrderInfo = createAction(
  '[Order] Reset order info'
);




