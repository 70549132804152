import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { coreInterceptorProviders } from '@app/core/http-interceptors';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ENVIRONMENT, TranslationService } from '@lib/rs-ngx';
import { errorHandlerProviders } from '@app/core/error-handlers';
import { environment } from '@environments/environment';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en-GB';
import localeDe from '@angular/common/locales/de';
import { corePersistentMetaReducer, coreReducer } from '@app/core/state/core.reducer';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RsMomentDateAdapter } from '@lib/rs-ngx';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

// Registering some locals to be able to used it for numbers and dates
registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe);


@NgModule({ declarations: [],
  imports: [
    StoreModule.forRoot(coreReducer, { metaReducers: [corePersistentMetaReducer] }),
    StoreDevtoolsModule.instrument({ maxAge: 25,
      logOnly: environment.production,
      connectInZone: true })
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    coreInterceptorProviders,
    errorHandlerProviders,
    { provide: DateAdapter, useClass: RsMomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    provideHttpClient(withInterceptorsFromDi())
  ] })
export class CoreModule {
  constructor(private readonly translateService: TranslationService) {
    translateService.initialize();
  }
}
