import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectStepReturnedVehicleValid } from '@app/core/state/steps-completed/steps-completed.selectors';

@Injectable({
  providedIn: 'root'
})
export class ReturnedVehicleGuard  {

  constructor(
    private readonly route: Router,
    private readonly store: Store
  ) { }

  public canActivate(): Observable<boolean> {
    return this.store.select(selectStepReturnedVehicleValid)
      .pipe(
        map((isValid) => {
          if (!isValid) {
            this.route.navigate(['/returned-vehicle']);
            return false;
          }
          return isValid;
        })
      );
  }

}
