<section
  class='stepper'
  [class.start-animation]='startAnimation'
>
  {{ 'STEP' | translate }}
  {{ _currentStep }}
  {{ 'OF' | translate }}
  {{ totalSteps }}

  <div>
    <div [style.width]='(100 / totalSteps) * _currentStep + "%"'></div>
  </div>
</section>