import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectStepLicensePlateValid } from '@app/core/state/steps-completed/steps-completed.selectors';

@Injectable({
  providedIn: 'root'
})
export class LicensePlateGuard  {

  constructor(
    private readonly route: Router,
    private readonly store: Store
  ) { }

  public canActivate(): Observable<boolean> {
    return this.store.select(selectStepLicensePlateValid)
      .pipe(
        map((isValid): boolean => {
          if (!isValid) {
            this.route.navigate(['/license-plate']);
            return false;
          }
          return isValid;
        })
      );
  }

}
