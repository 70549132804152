import { createReducer, on } from '@ngrx/store';
import { OrderInfo } from '@src/app/shared/models/order-info';
import { resetOrderInfo, setLicensePlateInput, setOrderInfoAndLeasingCompanies, setPickupDriverInfo, setPreviousVehicle } from '@app/core/state/order/order.actions';
import { LeasingCompany } from '@src/app/shared/models/leasing-company';
import { OrderDeliveryConfirmation } from '@app/shared/models/order-delivery-confirmation';
import { Base64String } from '@lib/rs-ngx';
import { produce } from 'immer';

export interface OrderState {
  img: Base64String,
  orderDelivery: OrderDeliveryConfirmation;
  orderInfo: OrderInfo | null;
  leasingCompanies: LeasingCompany[];
  licensePLateInput: string | null;
}

export const initialState: OrderState = {
  img: '',
  orderDelivery: {} as OrderDeliveryConfirmation,
  orderInfo: null,
  leasingCompanies: [],
  licensePLateInput: null
};

export const orderReducer = createReducer(
  initialState,
  on(setOrderInfoAndLeasingCompanies, (state, {
    orderInfo,
    leasingCompanies,
    img
  }) => {
    return produce(state, draft => {
      draft.img = img;
      draft.orderInfo = orderInfo;
      draft.leasingCompanies = leasingCompanies;
    });
  }),
  on(setPickupDriverInfo, (state, { driverInfo }) => {
    return produce(state, draft => {
      draft.orderDelivery.pickupDriver = driverInfo;
    });
  }),
  on(setPreviousVehicle, (state, { previousVehicle }) => {
    return produce(state, draft => {
      draft.orderDelivery.previousVehicle = previousVehicle;
    });
  }),
  on(setLicensePlateInput, (state, { licensePLateInput }) => {
    return produce(state, draft => {
      draft.licensePLateInput = licensePLateInput;
    });
  }),
  on(resetOrderInfo, state => {
    return {
      ...state,
      orderDelivery: {} as OrderDeliveryConfirmation,
      orderInfo: null,
      leasingCompanies: [],
      licensePLateInput: null,
      img: ''
    };
  })
);
