import { createAction, props } from '@ngrx/store';

export const setStepUserInfoValid = createAction(
  '[StepsCompleted] Set Step User Info Valid',
  props<{ stepUserInfoValid: boolean }>()
);

export const setStepLicensePlateValid = createAction(
  '[StepsCompleted] Set Step License Plate Valid',
  props<{ stepLicensePlateValid: boolean }>()
);

export const setStepReturnedVehicleValid = createAction(
  '[StepsCompleted] Set Step Returned Vehicle Valid',
  props<{ stepReturnedVehicleValid: boolean }>()
);

export const resetStepsCompleted = createAction(
  '[StepsCompleted] Reset Steps Completed'
);
