import { EnvironmentConfig } from '@lib/rs-ngx';

export const environment: EnvironmentConfig = {
  production: false,
  apiUrl: 'https://api.dar.tst.rentasolutions.org',
  useMockedServices: false,
  enableSentryMonitoring: true,
  sentryReleaseName: 'test-dar-ui-11825',
  environment: 'test',
  authUrl: 'https://authentication.tst.rentasolutions.org',
  topBarCdn: 'https://cdn.rentasolutions.org/topbar/tst/v1.3/',
  topBarEnvironment: 'tst',
  weblateEnvironment: 'tst'
};
