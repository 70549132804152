import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { Keys, localStorageSync } from 'ngrx-store-localstorage';
import { orderReducer, OrderState } from '@app/core/state/order/order.reducer';
import { stepsCompletedReducer, StepsCompletedState } from '@app/core/state/steps-completed/steps-completed.reducer';
import {
  rsLocalStorageMergeReducer
} from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export interface CoreState {
  order: OrderState;
  stepsCompleted: StepsCompletedState;
}

export const coreReducer: ActionReducerMap<CoreState> = {
  order: orderReducer as ActionReducer<OrderState>,
  stepsCompleted: stepsCompletedReducer as ActionReducer<StepsCompletedState>
};

const persistentReducerKeys = ['order', 'stepsCompleted'] as Keys;

export function localStorageSyncReducer(reducer: ActionReducer<CoreState>): ActionReducer<CoreState> {
  return localStorageSync({
    keys: persistentReducerKeys,
    storageKeySerializer: (key): string => 'dar_persistent_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer
  })(reducer);
}

export const corePersistentMetaReducer: MetaReducer<CoreState> = localStorageSyncReducer;

