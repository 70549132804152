<main
  [@routeAnimations]="getRouteAnimationData()"
  id='dar-ui-container'
>
  <dar-stepper
    [currentStep]='currentStep'
    [style.display]='currentStep ? "flex" : "none"'
  ></dar-stepper>

  <router-outlet (activate)="onActivatedRoute()"></router-outlet>
</main>