import {
  animate,
  AnimationGroupMetadata,
  AnimationQueryMetadata,
  group,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';

function slideTo(direction: string): (AnimationQueryMetadata | AnimationGroupMetadata)[] {
  const optional = { optional: true };
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        [direction]: 0,
        width: '100%'
      })
    ], optional),
    query(':enter', [style({ [direction]: '-100%' })]),
    group([
      query(':leave', [animate('600ms ease', style({ [direction]: '100%' }))], optional),
      query(':enter', [animate('600ms ease', style({ [direction]: '0%' }))])
    ])
  ];
}

export const DarUiRoutesAnimation =
  trigger('routeAnimations', [
    transition('Welcome => Login', slideTo('right') ),
    transition('Login => Welcome', slideTo('left') ),

    // transition('* => UserInfo', slideTo('right') ),

    transition('UserInfo => LicensePlate', slideTo('right') ),
    transition('LicensePlate => UserInfo', slideTo('left') ),

    transition('LicensePlate => ReturnedVehicle', slideTo('right') ),
    transition('ReturnedVehicle => LicensePlate', slideTo('left') ),

    transition('ReturnedVehicle => NewVehicle', slideTo('right') ),
    transition('NewVehicle => ReturnedVehicle', slideTo('left') ),

    transition('NewVehicle => OptionsList', slideTo('right') ),
    transition('OptionsList => NewVehicle', slideTo('left') ),

    transition('OptionsList => Confirmation', slideTo('right') ),
    transition('Confirmation => OptionsList', slideTo('left') ),

    transition('Confirmation => Success', slideTo('right') )
  ]);
