import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectStepUserInfoValid } from '@app/core/state/steps-completed/steps-completed.selectors';

@Injectable({
  providedIn: 'root'
})
export class UserInfoGuard  {

  constructor(
    private readonly route: Router,
    private readonly store: Store
  ) { }

  public canActivate(): Observable<boolean> {
    return this.store.select(selectStepUserInfoValid)
      .pipe(
        map((isValid) => {
          if (!isValid) {
            this.route.navigate(['/user-info']);
            return false;
          }
          return isValid;
        })
      );
  }

}
