import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ENVIRONMENT, RsMissingTranslationHandler, RsMultiHttpTranslateLoader } from '@lib/rs-ngx';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter } from 'rxjs';

const translationsLoaded = new BehaviorSubject(false);

function appInitializerFactory(
    loaded: BehaviorSubject<boolean>
): () => {} {
  return (): Promise<boolean> => {
    return new Promise((resolve, _reject) => {
      loaded
        .pipe(
          filter(translationLoaded => translationLoaded)
        )
        .subscribe({
          next: () => {
            resolve(true);
          }
        });
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: RsMultiHttpTranslateLoader,
        deps: [HttpClient, ENVIRONMENT, 'appName', 'translationsLoaded']
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: RsMissingTranslationHandler
      }
    })
  ],
  providers: [
    { provide: 'appName', useValue: 'DAR' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: ['translationsLoaded'],
      multi: true
    },
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS
    },
    JwtHelperService,
    {
      provide: 'translationsLoaded',
      useValue: translationsLoaded
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
