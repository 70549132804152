/** Http interceptor providers in outside-in order */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorsInterceptor } from '@app/core/http-interceptors/http-errors.interceptor';
import {
  RsAuthenticationInterceptor
} from '@rs-shared-lib/modules/rs-core/modules/rs-authentication/interceptors/rs-authentication.interceptor';


/** Http interceptor providers in outside-in order */
export const coreInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RsAuthenticationInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorsInterceptor,
    multi: true
  }
];
